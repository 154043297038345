/* eslint-disable import/no-cycle */
import React from 'react';
import classnames from 'classnames';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import EntityName from 'components/Globals/EntityName';
import usePageContext from 'utils/hooks/usePageContext';
import useAppContext from 'utils/hooks/useAppContext';
import { useTranslation } from 'src/i18n';
import { COMPONENTS } from 'components/Globals/Analytics/constants';

import { ENTITY_TYPE, TP, PAYWALL_TYPES, ORGANIZATION_TYPE_SLUGS, ORGANIZATION_TYPE_IDS } from 'constants/index';

import DatesList from './DatesList';

import classes from './VenuesListing.module.scss';

const VenueContentCommon = ({ maskText, stage, isCancelled, header, venueName }) => {
  const { setPaywallType } = useAppContext();

  const checkPermissions = () => {
    setPaywallType(PAYWALL_TYPES.ARCHIVED_WALL); // archive modal paywall
  };

  return (
    <>
      <div
        className={classnames(classes.venue, { [classes.cancelled]: !!isCancelled })}
        {...(maskText && { onClick: checkPermissions })}
      >
        {header}
        {venueName}
        {stage && (
          <Typography className={classnames(classes.venue__stageName, { [classes.maskText]: maskText })}>
            {stage}
          </Typography>
        )}
      </div>
    </>
  );
};

const VenueHeader = ({ venue, isLivestream, showHeader, isProSlug = false }) => {
  const { t } = useTranslation('NS_DISPLAY_V4');
  const city = venue?.city?.name;
  const country = venue?.country?.name;

  if (!venue) {
    return (
      <Typography
        weight="medium"
        variant="p"
        className={classnames(classes.venue__countryCity, {
          [classes.maskText]: venue?.id === -1,
          'paywall-content': venue?.id === -1,
        })}
        size={isProSlug ? 12 : 14}
      >
        {isLivestream ? t(`${TP}.FN_LIVESTREAM`) : t(`${TP}.FN_VENUE_NOT_ANNOUNCED`)}
      </Typography>
    );
  }

  if (!showHeader || (!city && !country)) {
    return null;
  }

  return (
    <Typography
      weight="medium"
      variant="p"
      className={classnames(classes.venue__countryCity, {
        [classes.proVenueCountryCity]: !!isProSlug,
        [classes.maskText]: venue?.id === -1,
      })}
    >
      {city}
      {!isProSlug && <>{country && city ? `, ${country}` : country}</>}
    </Typography>
  );
};

const VenueName = ({ venue, isProSlug = false, trackingData }) => {
  if (!venue) return null;

  return (
    <Typography
      className={classnames(classes.venue__name, {
        [classes.maskText]: venue?.id === -1,
        [classes.proVenueName]: !!isProSlug,
      })}
    >
      <SpriteIcon icon="location_on_black" className={classes.venue__locationIcon} />{' '}
      <EntityName entityType={ENTITY_TYPE.ORGANIZATION} entity={venue} trackingData={trackingData} isRaw withLocation />
    </Typography>
  );
};

export const VenueContent = ({
  venue,
  showHeader = false,
  isCancelled,
  isLivestream,
  isProSlug = false,
  trackingData,
}) => {
  const shouldMaskText = venue?.id === -1;
  const { navigate } = usePageContext();

  const handleOnClick = e => {
    e.stopPropagation();
  };

  if (!venue) {
    return (
      <VenueContentCommon
        maskText={shouldMaskText}
        header={<VenueHeader venue={venue} showHeader={showHeader} isLivestream={isLivestream} />}
      />
    );
  }

  const linkProps = navigate.getLinkProps({ entityType: ENTITY_TYPE.ORGANIZATION, entity: venue });
  const stage = venue?.stage?.name;

  if (shouldMaskText) {
    return (
      <div className={classes.venueWrapper}>
        <VenueContentCommon
          maskText={shouldMaskText}
          stage={stage}
          header={<VenueHeader venue={venue} showHeader={showHeader} isLivestream={isLivestream} />}
          venueName={<VenueName venue={venue} />}
        />
      </div>
    );
  }

  if (linkProps?.href) {
    const completeTrackingData = {
      ...trackingData,
      entityId: venue?.id,
      entityName: venue?.name,
      entityType: ENTITY_TYPE.ORGANIZATION,
    };
    return (
      <LinkButton
        isLink
        variant="text"
        {...linkProps}
        styles={{ root: classes.venueWrapper }}
        disableUnderline
        {...(isProSlug && { target: '_blank' })}
        onClick={handleOnClick}
        trackingData={completeTrackingData}
      >
        <VenueContentCommon
          maskText={shouldMaskText}
          stage={stage}
          isCancelled={isCancelled}
          header={
            <VenueHeader venue={venue} isLivestream={isLivestream} showHeader={showHeader} isProSlug={isProSlug} />
          }
          venueName={<VenueName venue={venue} isProSlug={isProSlug} trackingData={completeTrackingData} />}
        />
      </LinkButton>
    );
  }

  return (
    <VenueContentCommon
      maskText={shouldMaskText}
      stage={stage}
      isCancelled={isCancelled}
      header={<VenueHeader venue={venue} isLivestream={isLivestream} showHeader={showHeader} />}
      venueName={<VenueName venue={venue} />}
    />
  );
};

const VenuesListing = ({ venuesDates, entity: production, className, trackingData }) => {
  const completeTrackingData = {
    component: COMPONENTS.VENUES,
    ...trackingData,
    meta: { ...trackingData?.meta, organisationType: ORGANIZATION_TYPE_SLUGS[ORGANIZATION_TYPE_IDS.VENUE] },
  };
  return (
    <ul className={classnames(classes.venueItems, className)}>
      {venuesDates?.map(({ venue, dates, isLivestream }, index) => (
        <li className={classes.venueItem} key={`${venue?.id}-${venue?.stage?.id}-${index}`}>
          <VenueContent
            venue={venue}
            isLivestream={isLivestream}
            showHeader={venue?.city?.id !== venuesDates?.[index - 1]?.venue?.city?.id}
            isCancelled={production?.isCancelled}
            trackingData={completeTrackingData}
          />
          <div className={classes.datesList}>
            {Object.keys(dates).map((monthYear, datesIndex) => {
              const [month, year] = monthYear.split(' ');
              const yearOfLastDateGroup = Object.keys(dates)?.[datesIndex - 1]?.split?.(' ')?.[1];
              const datesOfLastVenue = Object.keys(venuesDates?.[index - 1]?.dates || {});
              const yearOfLastVenue = datesOfLastVenue?.[datesOfLastVenue?.length - 1]?.split?.(' ')?.[1];
              const shouldShowYear = datesIndex === 0 ? year !== yearOfLastVenue : year !== yearOfLastDateGroup;

              return (
                <DatesList
                  key={`${monthYear}-${datesIndex}`}
                  year={year}
                  month={month}
                  dates={dates[monthYear]}
                  isCancelled={production?.isCancelled}
                  shouldShowYear={shouldShowYear}
                  entity={production}
                  trackingData={completeTrackingData}
                />
              );
            })}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default VenuesListing;
